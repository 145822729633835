<template>
  <c-box flexGrow="1" :px="['20px', null]">
    <h1
      v-chakra
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      color="#111111"
      mb="32px"
    >
      Daily Coaching
    </h1>
    <c-flex v-if="!isEmpty" flexWrap="wrap">
      <c-button
        v-for="item in category"
        :key="item.value"
        :color="selectedCategory == item.value ? 'brand.900' : '#888888'"
        :border-color="selectedCategory == item.value ? 'brand.900' : '#888888'"
        :variant="selectedCategory == item.value ? 'solid' : 'outline'"
        @click="onSelectCategory(item.value)"
        border="1px"
        fontSize="16px"
        lineHeight="24px"
        px="26px"
        py="6px"
        borderRadius="36px"
        mr="13px"
        mb="13px"
      >
        {{ item.label }}
      </c-button>
    </c-flex>

    <EmptyPage
      v-if="isEmpty"
      label="Anda tidak memiliki program aktif, ingin melihat <br> Daily Coaching?"
    />

    <c-flex
      v-if="!isEmpty"
      flex="1"
      flex-direction="column"
      justify-content="center"
      align-items="center"
      :my="['30px', '60px']"
      :mb="['100px', null]"
    >
      <c-flex
        v-for="(item, index) in items"
        :key="index"
        position="relative"
        w="100%"
        borderBottom="1px solid #F2F2F2"
        :py="[0, '22px']"
        :px="[0, '26px']"
        :flex-dir="['column', 'row']"
        mb="17px"
        background="#FFFFFF"
        border="1px solid #C4C4C4"
        box-sizing="border-box"
        box-shadow="0px 5px 30px rgba(0, 0, 0, 0.05)"
        border-radius="12px"
        cursor="pointer"
        @click="onDetail(item)"
      >
        <c-box
          bg="#C4C4C4"
          d="flex"
          :min-w="[null, '96px']"
          :w="['100%', '96px']"
          :h="['auto', '96px']"
          :mr="[null, '20px']"
          position="relative"
        >
          <c-image
            w="100%"
            h="100%"
            :borderRadius="['6px', '12px']"
            :src="
              item.media ? getPhotoProgram(item.media.url) : getPhotoProgram()
            "
          />
          <c-heading
            :d="['block', 'none']"
            as="h4"
            fontWeight="bold"
            color="white"
            :font-size="['16px', '18px']"
            :lineHeight="['24px', '27px']"
            flex="1 100%"
            position="absolute"
            left="0"
            bottom="16px"
            mx="20px"
          >
            {{ item.title }}
          </c-heading>
        </c-box>
        <c-box flexGrow="1" :px="['20px', null]" :py="['13px', null]">
          <c-flex justify="space-between" align="baseline">
            <c-heading
              :d="['none', 'block']"
              as="h4"
              fontWeight="bold"
              color="black.900"
              :font-size="['16px', '18px']"
              :lineHeight="['24px', '27px']"
              flex="1 60%"
            >
              {{ item.title }}
            </c-heading>
            <c-badge
              v-if="!item.showOnDay"
              :variant="item.isRead ? 'solid' : 'subtle'"
              :backgroundColor="item.isRead ? 'brand.900' : '#F2F2F2'"
              :variant-color="item.isRead ? 'green' : ''"
              py="5px"
              px="12px"
              borderRadius="30px"
              fontSize="14px"
              fontWeight="normal"
              lineHeight="21px"
              textTransform="unset"
              display="flex"
              :position="['absolute', 'relative']"
              :top="['10px', 'unset']"
              :right="['10px', 'unset']"
            >
              <c-image
                v-if="item.isRead"
                w="16px"
                mr="7px"
                :src="require('@/assets/icon-eye.svg')"
                alt="ilustrasi"
              />
              {{ item.isRead ? "Sudah Dibaca" : "Belum Dibaca" }}
            </c-badge>
            <c-badge
              v-if="item.showOnDay"
              backgroundColor="#F4CC46"
              variant-color="#F4CC46"
              color="#927102"
              py="5px"
              px="12px"
              borderRadius="30px"
              fontSize="14px"
              fontWeight="normal"
              lineHeight="21px"
              textTransform="unset"
              display="flex"
              align-self="flex-start"
              :position="['absolute', 'relative']"
              :top="['10px', 'unset']"
              :right="['10px', 'unset']"
            >
              <c-image
                w="16px"
                mr="7px"
                :src="require('@/assets/icon-clock.svg')"
                alt="ilustrasi"
              />
              Konten dapat dibuka pada hari ke-{{ item.showOnDay }}
            </c-badge>
          </c-flex>
          <c-text
            fontWeight="500"
            color="brand.900"
            fontSize="12px"
            lineHeight="18px"
            pb="6px"
          >
            {{ getLabelCategory(item.category) }}
          </c-text>
          <c-text
            color="black.900"
            :font-size="[null, '14px']"
            :lineHeight="[null, '21px']"
            v-html="item.description"
          />
        </c-box>
      </c-flex>

      <c-flex justifyContent="center" w="100%" marginTop="40px">
        <c-flex justifyContent="center">
          <c-button
            px="0"
            py="4px"
            w="32px"
            h="32px"
            backgroundColor="transparent"
            :isDisabled="currentPage === 1"
            @click="prevPage"
          >
            <c-icon name="chevron-left" size="24px" />
          </c-button>

          <c-button
            px="0"
            py="4px"
            w="32px"
            h="32px"
            mx="4px"
            backgroundColor="rgba(0, 140, 129, 0.3)"
            color="brand.900"
            v-chakra="{
              ':hover': {
                bg: 'rgba(0, 140, 129, 0.3)',
                color: 'brand.900',
              },
            }"
            v-for="page in pages"
            :key="page"
            :bg="
              page === currentPage
                ? ['rgba(0, 140, 129, 0.3)']
                : ['transparent']
            "
            as="router-link"
            :to="{ name: $route.name, query: { ...$route.query, page } }"
          >
            {{ page }}
          </c-button>

          <c-button
            px="0"
            py="4px"
            w="32px"
            h="32px"
            backgroundColor="transparent"
            :isDisabled="pages.length === page"
            @click="nextPage"
          >
            <c-icon name="chevron-right" size="24px" />
          </c-button>
        </c-flex>
      </c-flex>
    </c-flex>

    <c-alert-dialog
      :is-open="isOpen"
      :least-destructive-ref="$refs.cancelRef"
      :on-close="closeDialog"
    >
      <c-alert-dialog-overlay z-index="1" />
      <c-alert-dialog-content
        text-align="center"
        z-index="2"
        top="60px"
        pt="24px"
        pb="10px"
        border-radius="6px"
      >
        <c-icon
          name="warning"
          mx="auto"
          my="20px"
          size="72px"
          color="yellow.400"
        />
        <c-alert-dialog-header
          font-size="lg"
          font-weight="bold"
          text-align="center"
          py="0"
        >
          Perhatian
        </c-alert-dialog-header>
        <c-alert-dialog-body text-align="center">
          Masih ada konten yang belum kamu baca nih, <br />
          Yakin ingin melewatkan konten sebelumnya?
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button
            h="auto"
            py="13px"
            px="18px"
            border-radius="60px"
            ref="cancelRef"
            @click="
              $router.push({
                name: 'content-challenge.detail',
                params: { id: previousContentId },
              })
            "
          >
            Lanjutkan baca
          </c-button>
          <c-button
            h="auto"
            py="13px"
            px="18px"
            border-radius="60px"
            variantColor="brand"
            @click="
              $router.push({
                name: 'content-challenge.detail',
                params: { id: previousContentId },
              })
            "
            ml="3"
            min-w="80px"
          >
            Baca sebelumnya
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import EmptyPage from "@/views/content-challenge/empty.vue";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "DailyCoachingPage",
  components: { EmptyPage },
  mixins: [generalMixin],
  data() {
    return {
      selectedCategory: "",
      page: 1,
      perPage: 10,
      isOpen: false,
      previousContentId: null,
    };
  },
  computed: {
    ...mapGetters({
      items: "contentChallenge/items",
      meta: "contentChallenge/meta",
      category: "contentChallenge/category",
    }),
    isEmpty() {
      if (_.isEmpty(this.items) || this.items.length === 0) return true;
      return false;
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10);
    },
    pages() {
      let total = this.meta.total ?? 1;
      let perPage = this.perPage;
      let length = Math.ceil(total / perPage);
      return Array.from({ length }, (_, idx) => idx + 1);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1;
        this.selectedCategory = route.query.category
          ? route.query.category
          : "";

        let params = new URLSearchParams();
        params.set("type", "daily_coaching");
        params.set("category", this.selectedCategory);
        params.set("page", this.page);
        params.set("pagesize", this.perPage);
        this.listDailyCoachingClient(params);
      },
    },
  },
  methods: {
    ...mapActions({
      listDailyCoachingClient: "contentChallenge/listContentChallengeClient",
    }),
    onSelectCategory(value) {
      this.page = 1;
      this.selectedCategory = value;
      this.$router.push({
        name: "content-challenge.daily-coaching",
        query: {
          category: value,
        },
      });
    },
    getLabelCategory(value) {
      if (value) {
        return this.category.find((dt) => dt.value === value)?.label;
      }
      return "-";
    },
    onDetail(item) {
      this.previousContentId = null;
      if (!item.canOpen) {
        return this.$toast({
          description: `Konten dapat dibuka di hari ke -${item.showOnDay}`,
          status: "info",
          duration: 5000,
          position: "bottom-right",
        });
      }
      if (item.previousContentId) {
        this.previousContentId = item.previousContentId;
        return (this.isOpen = true);
      }
      this.$router.push({
        name: "content-challenge.detail",
        params: { id: item.id },
      });
    },
    prevPage() {
      this.page -= 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
    nextPage() {
      this.page += 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
    closeDialog() {
      this.isOpen = false;
    },
  },
  mounted() {},
};
</script>
